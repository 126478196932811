<template>
  <location-bar
    :with-title="false"
    :flex-size="flexSize"
    :with-filters="filters"
    @selected-region="regionsFilter"
    @selected-country="countriesFilter"
    @selected-district="districtsFilter"
  >
    <div class="flex xs12 md2">
      <va-button color="primary" :disabled="loading" @click.prevent="submit">
        <text-loading :loading="loading">{{
          $t("reports.generate.button")
        }}</text-loading>
      </va-button>
    </div>
  </location-bar>
</template>
<script>
import { mapGetters } from "vuex";
import { validatorMixin } from "@/services/validator";
const LocationBar = () => import(/* webpackPrefetch: true */ "./Location");

export default {
  name: "yearly-bar",
  components: {
    LocationBar,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    selection: {
      type: String,
      default: null,
    },
  },
  mixins: [validatorMixin],
  data() {
    return {
      months: [],
      filters: [],
      selectedRegion: null,
      selectedCountry: null,
      selectedDistrict: null,
      form: {
        year: {
          value: new Date().getFullYear(),
          validate: {
            required: true,
            maxValue: new Date().getFullYear() + 1,
            minValue: 2000,
          },
          errors: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    flexSize() {
      let def = "md4";
      if (Array.isArray(this.filters)) {
        switch (this.filters.length) {
          case 1:
            def = "md12";
            break;
          case 2:
            def = "md6";
            break;
        }
      }
      return def;
    },
  },
  created() {
    let route = [];
    switch (this.selection) {
      case "region":
        if (this.currentUser.can("Regions", "index")) {
          route = ["regions"];
        }
        break;
      case "country":
        if (this.currentUser.can("Countries", "index")) {
          route = ["regions", "countries"];
        }
        break;
      case "district":
        if (this.currentUser.can("Districts", "index")) {
          route = ["regions", "countries", "districts"];
        }
        break;
    }

    this.filters = route.slice(0);
  },
  methods: {
    regionsFilter(region) {
      this.selectedRegion = region;
    },
    countriesFilter(country) {
      this.selectedCountry = country;
    },
    districtsFilter(district) {
      this.selectedDistrict = district;
    },
    resetForm() {
      this.form.year.value = new Date().getFullYear();
      this.selectedRegion = null;
      this.selectedCountry = null;
      this.selectedDistrict = null;
    },
    submit() {
      this.validateAll();
      if (!this.formReady) return;

      const generate = {};
      generate.date = this.getFormValue("year").toString();

      if (this.selectedRegion) {
        generate.region = this.selectedRegion.id;
      }
      if (this.selectedCountry) {
        generate.country = this.selectedCountry.id;
      }
      if (this.selectedDistrict) {
        generate.district = this.selectedDistrict.id;
      }

      this.$emit("submit", generate);
      this.resetForm();
    },
  },
};
</script>
